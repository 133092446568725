export function useVapor() {
  const { $createPresignedRequest, $uploadVaporFile, $vaporApiFetch } =
    useNuxtApp();

  return {
    createPresignedRequest: $createPresignedRequest,
    store: $uploadVaporFile,
    apiFetch: $vaporApiFetch,
  };
}
